import React from "react";
import "./index.css";

const Skills = () => {
    return (
        <div id='skills_div'>
            <p className='fs-4'><i className='fa-solid fa-gears'/> Technical Skills</p>
            <hr/>
            <div id='skills_list'>
                <div id='skills_p1'>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Programming Languages</p>
                        <ul>
                            <li>Java</li>
                            <li>JavaScript</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Databases</p>
                        <ul>
                            <li>MySQL</li>
                            <li>MongoDB</li>
                            <li>Redis</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Version Control</p>
                        <ul>
                            <li>Git</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Containerization/Orchestration</p>
                        <ul>
                            <li>Docker</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Web Servers</p>
                        <ul>
                            <li>Nginx</li>
                        </ul>
                    </code>
                </div>
                <div id='skills_p2'>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Frameworks/Libraries</p>
                        <ul>
                            <li>Spring Boot</li>
                            <li>React.js</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Message Queues/Streams</p>
                        <ul>
                            <li>Apache Kafka</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> CI/CD</p>
                        <ul>
                            <li>Jenkins</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Monitoring/Reporting</p>
                        <ul>
                            <li>Prometheus</li>
                            <li>Grafana</li>
                        </ul>
                    </code>
                    <code>
			<p><i className="fa-solid fa-chevron-right"></i> Operating Systems</p>
                        <ul>
                            <li>Windows</li>
                            <li>Linux</li>
                        </ul>
                    </code>
                </div>
            </div>
        </div>
    );
}

export default Skills;
