import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.css';
import Resume from "../../files/Syed Riadh Hossen.pdf";
import emailjs from "@emailjs/browser";

const App = () => {
    const helpCommands = "help, clear, about, work, education, skills, contact, hello, resume";

    const [terminalLines, setTerminalLines] = useState([]);
    const [commandHistory, setCommandHistory] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [historyIndex, setHistoryIndex] = useState(-1);
    const inputRef = useRef(null);
    const terminalRef = useRef(null);
    const terminalBodyRef = useRef(null);

    const displayWelcomeText = useCallback(() => {
        const welcomeText = [
            "visitor@syedriadh.com:~$ welcome",
            `${getGreeting()}! Welcome to my portfolio.`,
            <>I am <span style={{color: "#0fffd7"}}>Syed Riadh Hossen</span>, a Software Engineer</>,
            "I specialize in crafting web applications and services using Java and Spring Boot.",
            "\u00A0",
            "Type a command: " + helpCommands
        ];
        setTerminalLines(() => [...welcomeText]);
    }, []);

    useEffect(() => {
        displayWelcomeText();

        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }

        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setTimeout(() => {
                    inputRef.current.focus();
                }, 0);
            }
        };

        const handleBlur = () => {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0);
        }

        document.addEventListener('click', handleClickOutside);
        if (inputRef.current) {
            inputRef.current.addEventListener('blur', handleBlur);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            if (inputRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                inputRef.current.removeEventListener('blur', handleBlur)
            }
        };
    }, [displayWelcomeText]);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) {
            return "Good Morning";
        } else if (hour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            if (inputRef.current) {
                const partial = inputValue.trim().toLowerCase();
                const suggestions = ['help', 'clear', 'about', 'work', 'education', 'skills', 'contact', 'resume'];
                const match = suggestions.find(cmd => cmd.startsWith(partial));
                if (match) {
                    setInputValue(match);
                    setCaretToEnd();
                }
            }
        } else if (e.key === 'Enter') {
            processCommand(inputValue.trim());
            setCommandHistory((prevHistory) => [...prevHistory, inputValue]);
            setHistoryIndex(commandHistory.length + 1);
            setInputValue('');
        } else if (e.key === 'ArrowUp') {
            if (historyIndex > 0) {
                setHistoryIndex(historyIndex - 1);
                setInputValue(commandHistory[historyIndex - 1] || '');
                setCaretToEnd();
            }
        } else if (e.key === 'ArrowDown') {
            if (historyIndex < commandHistory.length - 1) {
                setHistoryIndex(historyIndex + 1);
                setInputValue(commandHistory[historyIndex + 1] || '');
                setCaretToEnd();
            } else {
                setHistoryIndex(commandHistory.length);
                setInputValue('');
            }
        }
    };

    const setCaretToEnd = () => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
            }
        }, 0);
    }

    const processCommand = (command) => {
        if (!command) {
            return addTerminalLine("visitor@syedriadh.com:~$");
        }

        addTerminalLine(`visitor@syedriadh.com:~$ ${command}`);

        if (command.toLowerCase().startsWith('hello')) {
            if (command.toLowerCase() === 'hello --help' || command.toLowerCase() === 'hello -h') {
                displayHelloHelp();
            } else {
                console.log("Command received: " + command);
                sayHello(command);
            }
        } else {
            switch (command.toLowerCase()) {
                case 'help':
                    displayHelp();
                    break;
                case 'about':
                    displayAbout();
                    break;
                case 'work':
                    displayWork();
                    break;
                case 'education':
                    displayEducation();
                    break;
                case 'skills':
                    displaySkills();
                    break;
                case 'contact':
                    displayContact();
                    break;
                case 'resume':
                    displayResume();
                    break;
                case 'clear':
                    setTerminalLines([]);
                    break;
                default:
                    addTerminalLine(`Unknown command: ${command}`);
            }
        }
        addBlankLine();
    };

    const addTerminalLine = (text) => {
        setTerminalLines((prevLines) => [...prevLines, text]);
        scrollToBottom();
    };

    const addBlankLine = () => {
        setTerminalLines((prevLines) => [...prevLines, '\u00A0']);
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (terminalBodyRef.current) {
                terminalBodyRef.current.scrollTop = terminalBodyRef.current.scrollHeight;
            }
        }, 0);
    };

    const displayHelp = () => {
        const helpText = [
            "Available commands:",
            "\u00A0\u00A0clear:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Clear console",
            "\u00A0\u00A0about:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0About me",
            "\u00A0\u00A0work:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0My work experiences",
            "\u00A0\u00A0education:\u00A0\u00A0\u00A0\u00A0My educational qualifications",
            "\u00A0\u00A0skills:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0My skills",
            "\u00A0\u00A0contact:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0My contact links",
            "\u00A0\u00A0hello:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Send message directly",
            "\u00A0\u00A0resume:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0My downloadable PDF resume",
            "\u00A0\u00A0help:\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0See available commands"
        ];

        helpText.forEach((line) => addTerminalLine(line));
    }

    const displayAbout = () => {
        const about = [
            "Hello! I am Syed Riadh Hossen, a Software Engineer with proven ability to work and collaborate efficiently in an agile, fast-moving environment, contributing to the delivery of scalable, efficient software solutions through object-oriented approaches. I am a detail-oriented person with passion for tackling complex problems and commitment to delivering high-quality code, regularly refreshing my skills to ensure to keep up with ongoing developments.",
            "I excel in both work and personal pursuits with a calm focus on detail and a quick grasp of new skills with ability to thrive in dynamic environment, adapting and innovating to contribute to success."
        ];
        about.forEach((line) => addTerminalLine(line));
    };

    const displayWork = () => {
        const work = [
            "* Software Engineer (Consulting)",
            "\u00A0\u00A0Self Employed",
            "\u00A0\u00A0Sept 2024 - Present",
            "\u00A0",
            "* Application Developer",
            <><span>{"\u00A0\u00A0"}<a href="https://banglalink.net" target="_blank" rel="noreferrer">Banglalink Digital Communications Limited</a></span></>,
            <><span
                style={{fontSize: "small"}}>{"\u00A0\u00A0\u00A0"}Outsourced from Frontdesk Bangladesh Limited</span></>,
            "\u00A0\u00A0Dhaka, Bangladesh",
            "\u00A0\u00A02 Jul 2023 - 30 Nov 2024",
            "\u00A0",
            "* Junior Software Engineer",
            "\u00A0\u00A0Tech Cloud Ltd.",
            "\u00A0\u00A0Dhaka, Bangladesh",
            "\u00A0\u00A09 Jan 2023 - 2 Jul 2023",
        ];
        work.forEach((line) => addTerminalLine(line));
    };

    const displayEducation = () => {
        const education = [
            "* Southeast University",
            "\u00A0\u00A0Bachelor of Science",
            "\u00A0\u00A0Computer Science & Engineering",
            "\u00A0\u00A0Graduated: 2023",
        ];
        education.forEach((line) => addTerminalLine(line));
    };

    const displaySkills = () => {
        const skills = [
            <>
                <strong>* Programming Languages</strong>
            </>,
            "\u00A0\u00A0Java, JavaScript",
            "\u00A0",
            <>
                <strong>* Frameworks/Libraries</strong>
            </>,
            "\u00A0\u00A0Spring Boot, React.js",
            "\u00A0",
            <>
                <strong>* Databases</strong>
            </>,
            "\u00A0\u00A0MySQL, MongoDB, Redis",
            "\u00A0",
            <>
                <strong>* Version Control</strong>
            </>,
            "\u00A0\u00A0Git",
            "\u00A0",
            <>
                <strong>* Message Queues/Streams</strong>
            </>,
            "\u00A0\u00A0Apache Kafka",
            "\u00A0",
            <>
                <strong>* CI/CD</strong>
            </>,
            "\u00A0\u00A0Jenkins",
            "\u00A0",
            <>
                <strong>* Containerization/Orchestration</strong>
            </>,
            "\u00A0\u00A0Docker",
            "\u00A0",
            <>
                <strong>* Web Servers</strong>
            </>,
            "\u00A0\u00A0Nginx",
            "\u00A0",
            <>
                <strong>* Monitoring/Reporting</strong>
            </>,
            "\u00A0\u00A0Prometheus, Grafana",
            "\u00A0",
            <>
                <strong>* Operating Systems</strong>
            </>,
            "\u00A0\u00A0Windows, Linux",
            "\u00A0"
        ];
        skills.forEach((line) => addTerminalLine(line));
    };

    const displayContact = () => {
        const contacts = [
            <>Email: <a href='mailto:riadhhossen@gmail.com'>riadhhossen@gmail.com</a></>,
            <>LinkedIn: <a href='https://linkedin.com/in/syedriadhhossen' target='_blank'
                           rel='noreferrer'>linkedin/syedriadhhossen</a></>,
            <>GitHub: <a href='https://github.com/hossensyedriadh' target='_blank'
                         rel='noreferrer'>github/hossensyedriadh</a></>
        ];
        contacts.forEach((line) => addTerminalLine(line));
    };

    const displayHelloHelp = () => {
        const tips = [
            "Usage:",
            <>
                <span>hello --name <em>your_name_here</em> --email <em>your_email_here</em> --message <em>your_message_here</em></span></>,
            "or",
            <>
                <span>hello -n <em>your_name_here</em> -e <em>your_email_here</em> -m <em>your_message_here</em></span></>,
            "\u00A0",
            "Constraints:",
            "\u00A0Name: max 40 characters",
            "\u00A0Email: max 100 characters",
            "\u00A0Message: max 1000 characters",
            "\u00A0* Use single or double quotes with each parameter"
        ];
        tips.forEach((line) => addTerminalLine(line));
    }

    const parseHelloCommand = (command) => {
        const regex = /--name\s+(['"])(.*?)\1|--email\s+(['"])(.*?)\3|--message\s+(['"])(.*?)\5|(-n)\s+(['"])(.*?)\8|(-e)\s+(['"])(.*?)\11|(-m)\s+(['"])(.*?)\14/g;

        const params = {
            name: null,
            email: null,
            message: null,
        };

        let match;
        while ((match = regex.exec(command)) !== null) {
            if (match[2]) params.name = match[2].trim();
            if (match[4]) params.email = match[4].trim();
            if (match[6]) params.message = match[6].trim();
            if (match[9]) params.name = match[9].trim();
            if (match[12]) params.email = match[12].trim();
            if (match[15]) params.message = match[15].trim();
        }

        return params;
    };

    const sayHello = (command) => {
        const trimmedCommand = command.trim();

        if (trimmedCommand.startsWith('hello')) {
            const params = parseHelloCommand(trimmedCommand);

            if (params.name && params.email && params.message) {
                if (!/^[a-zA-Z\s.]+$/.test(params.name)) {
                    addTerminalLine(<><span style={{color: "red"}}>Error: Name can only contain letters, full-stops, and spaces.</span></>);
                    return;
                }

                if (params.name.length > 40) {
                    addTerminalLine(<><span style={{color: "red"}}>Error: Name cannot exceed 40 characters.</span></>);
                    return;
                }

                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(params.email)) {
                    addTerminalLine(<><span style={{color: "red"}}>Error: Invalid email format.</span></>);
                    return;
                }

                if (params.email.length > 100) {
                    addTerminalLine(<><span
                        style={{color: "red"}}>Error: Email cannot exceed 100 characters.</span></>);
                    return;
                }

                if (params.message.length < 10 || params.message.length > 1000) {
                    addTerminalLine(<><span
                        style={{color: "red"}}>Error: Message must be within 10-1000 characters.</span></>);
                    return;
                }

                let emailData = {
                    "name": params.name,
                    "email": params.email,
                    "message": params.message
                };

                emailjs.send("service_ggbhfp3", "template_ivaknyl", emailData, "sDM2czMLSh5wfXY85")
                    .then(() => {
                        addTerminalLine(<><span style={{color: "green"}}>Message sent!</span></>);
                    }, () => {
                        addTerminalLine(<><span
                            style={{color: "red"}}>Failed to send message. Try again later</span></>);
                    });
            } else {
                addTerminalLine(<>Invalid command. Please include name, email, message parameters.<br/>Try 'hello
                    --help' or 'hello -h'.</>);
            }
        } else {
            addTerminalLine("Command not recognized. Try 'hello --help' or 'hello -h'.");
        }
    }

    const handleResumeDownload = () => {
        let confirm = window.confirm("Download resume?");

        if (confirm) {
            document.getElementById('pseudo_download_button').click();
        }
    }

    const displayResume = () => {
        addTerminalLine(<>Click <span className='fake-link' onClick={handleResumeDownload}>here</span> to download
            my
            resume
            <a id='pseudo_download_button' href={Resume} target='_self' download='Resume of Syed Riadh Hossen'
               hidden={true}>Resume</a>
        </>);
    };

    return (
        <div className="main-container">
            <div className="terminal" ref={terminalRef}>
                <div className="terminal-header">
                    <div className="macos-buttons">
                        <button className="macos-button close"></button>
                        <button className="macos-button minimize"></button>
                        <button className="macos-button maximize"></button>
                    </div>
                </div>
                <div className="terminal-body" ref={terminalBodyRef}>
                    {terminalLines.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
                <div className="input-line">
                <span className="prompt">
                  <span style={{color: 'lime'}}>visitor</span>
                  @<span style={{color: '#4fc3f7'}}>syedriadh.com</span>
                  <span style={{color: 'red'}}>:~$ </span>
                </span>
                    <input
                        id="input-field"
                        type="text"
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        autoComplete="off"
                    />
                </div>
            </div>
            <div id="footer">
                <code>Built by <span style={{color: "#0fffd7"}}>Syed Riadh Hossen</span></code>
            </div>
        </div>
    );
};

export default App;
